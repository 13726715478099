<template>
  <div id="a-container">
    <Carousel :isSliderActive="false" :isCertificatesTitle="true">
      <div id="a-carousel-inactive-content">
        <div id="a-certificate-action-title">
          <h4>
            Resultados encontrados para {{ user.first_name }}
            {{ user.last_name }}
          </h4>
        </div>

        <DataTable
          :value="attempts"
          :paginator="true"
          :rows="4"
          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
        >
          <!-- Fields defined in data section -->
          <Column
            v-for="col of fields"
            :field="col.field"
            :header="col.header"
            :key="col.field"
            sortable
          />

          <!-- Action buttons -->
          <Column headerStyle="width: 9rem">
            <template #body="slotProps">
              <div class="a-container-tab-btns">
                <button
                  class="a-icon-tab-btn a-detail-btn"
                  @click="calculateNeededToApprove(slotProps.data)"
                >
                  <img :src="require('@/assets/icons/eye-white.svg')" />
                </button>
              </div>
            </template>
          </Column>
        </DataTable>

        <!-- <div id="a-carousel-btn">
          <primary-button
            :isLoading="isLoading"
            :title="'Descargar'"
            @onClick="downloadCertificate"
          />
          <Button
            label="Regresar"
            class="p-button-danger"
            :loading="isLoading"
            @click="onCancel"
          />
        </div> -->
      </div>
    </Carousel>
  </div>
  <completed
    :user="user"
    :isOverlayShowing="isCertificateGenerated"
    :test="selectedTestToShow"
    @onExit="isCertificateGenerated = false"
  />
  <Toast />
</template>

<script>
import Carousel from "@/components/carousel/Carousel.vue";
// import PrimaryButton from "../components/widgets/PrimaryButton.vue";
import { mapState } from "vuex";
import Completed from "../components/certificates/completed.vue";
export default {
  name: "InitialTestPage",
  components: {
    Carousel,
    Completed,
    // PrimaryButton
  },
  data() {
    return {
      isLoading: false,
      selectedTestToShow: null,
      isCertificateGenerated: false,
      neededToPass: 0,
      totalWeight: 0,
      score: 0,
      attempts: null,
      fields: [
        {
          field: "id",
          header: "No. de prueba",
        },
        {
          field: "user.email",
          header: "Correo electrónico",
        },
        {
          field: "created_at",
          header: "Fecha",
        },
      ],
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("test", ["selectedTest"]),
  },
  mounted() {
    this.getAttempts();
  },
  methods: {
    getAttempts() {
      this.$store
        .dispatch("answers/GET_ANSWERS_BY_USER")
        .then((response) => {
          this.attempts = response;
        })
        .catch((error) => {
          if (error == "token") {
            this.getAttempts();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          }
        });
    },
    getAnswersFromTest(data) {
      this.$store
        .dispatch("answers/GET_ANSWERS_BY_ATTEMPT", data.id)
        .then((response) => {
          this.score = 0;

          response.forEach((answer) => {
            this.score = this.score + answer.option.weighted;
          });

          if (this.score >= this.neededToPass) {
            this.selectedTestToShow = data.test;
            this.isCertificateGenerated = true;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "No se puede certificar este intento.",
              detail: "No has superado la calificación mínima requerida.",
              life: 5000,
            });
          }

          // let cantCertificate = false;
          // response.forEach((answer) => {
          //   if (
          //     answer.question.questionType.name === "Pregunta abierta" &&
          //     !answer.isReview
          //   ) {
          //     cantCertificate = true;
          //     console.log("cant");
          //   }
          // });
          // if (cantCertificate) {
          //   this.selectedTest = data.test;
          //   this.isCertificateGenerated = true;
          // }
        })
        .catch((error) => {
          if (error == "token") {
            this.getAnswersFromTest();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          }
        });
    },
    calculateNeededToApprove(data) {
      this.$store
        .dispatch("test/GET_QUESTION_LIST", data.test)
        .then(() => {
          this.neededToPass = 0;
          this.totalWeight = 0;
          this.selectedTest.questionList.forEach((question) => {
            this.neededToPass = this.neededToPass + question.approved;
            this.totalWeight = this.totalWeight + question.weighted;
          });

          this.getAnswersFromTest(data);
        })
        .catch((error) => {
          if (error == "token") {
            this.calculateNeededToApprove();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          }
        });
    },
    onCancel() {
      this.$router.push({ name: "Home" });
    },

    downloadCertificate() {
    },
    searchCertificate() {
    },
  },
};
</script>

<style>
#a-container {
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

#a-carousel-inactive-content {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
#a-carousel-inactive-content #a-row {
  display: flex;
  flex-flow: nowrap row;
  justify-content: space-between;
}

#a-carousel-inactive-content #a-carousel-text {
  max-width: 470px;
  margin: 0 0 0 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
#a-carousel-inactive-content #a-carousel-text h2 {
  color: var(--color-1);
  font-family: "Quicksand", sans-serif !important;
  font-size: 25px;
}
#a-carousel-inactive-content #a-carousel-text p {
  font-size: 18px;
}

#a-carousel-detail {
  display: flex;
}

#a-carousel-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
#a-carousel-btn button {
  max-width: 290px;
  margin: 20px 20px 70px 20px;
}
#a-certificate-action-title {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
#a-certificate-action-title h4 {
  font-family: Quicksand;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.56px;
  color: var(--color-2);
}
#a-certificate-action {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
#a-certificate-action .p-inputtext {
  border: solid 1.5px #b0b0b0 !important;
  width: 300px;
}
#a-certificate-action #a-help-btn {
  margin: 0 16px;
}
#a-certificate-action .a-main-btn {
  padding: 0;
}
</style>
