<template>
  <div v-if="isOverlayShowing" id="a-certificate">
    <div id="a-rounded-icon-btn" class="a-close-certificate" @click="cancel">
      <img :src="require('@/assets/icons/logout.svg')" style="height: 24px" />
    </div>
    <div id="a-main-container">
      <img :src="require('@/assets/images/logo/villavo-white.svg')" />
      <div id="a-main-info">
        <span>Certifica a</span>
        <span class="a-name">{{ user.first_name }} {{ user.last_name }}</span>
        <br />
        <br />
        <br />
        <span>Por la participación y éxito en el curso virtual</span>
        <span class="a-test-name">{{ test.name }}</span>
      </div>
    </div>

    <div id="a-bottom-container">
      <div>
        <span style="color: #00b3ff">Certificado online creado en:</span>
        <br />
        <span>Vilavicencio, Meta. {{ date }}</span>
      </div>
      <div class="a-sign">Dirección de personal</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "completedDiploma",
  props: {
    isOverlayShowing: Boolean,
    user: Object,
    test: Object,
  },
  data() {
    return {
      date: "",
    };
  },
  emits: ["onExit"],
  mounted() {
    let d = new Date();
    this.date = d.toLocaleString();
  },
  methods: {
    cancel() {
      this.$emit("onExit");
    },
  },
};
</script>
<style>
.a-close-certificate {
  position: absolute;
  z-index: 9999999;
  top: 10px;
  right: 10px;
}
</style>

<style scoped>
#a-main-container {
  padding: 50px;
  width: 100%;
  height: 80%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
#a-main-container img {
  height: 100px;
  margin-bottom: 50px;
}
#a-main-info {
  padding-top: 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
#a-bottom-container {
  height: 20%;
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.a-sign {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  color: #035ac4;
}
#a-certificate {
  width: 100%;
  height: 100%;
  z-index: 8888888;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  position: absolute;
  background: var(--color-1);
  top: 0;
}
#a-certificate .a-test-name {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.07;
  text-align: center;
  color: #fff;
}
#a-certificate span {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  color: #fff;
}
#a-bottom-container span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
  text-align: left;
  color: #035ac4;
}
#a-certificate .a-name {
  font-family: Montserrat;
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;
  text-align: center;
  color: #fff;
}
</style>
